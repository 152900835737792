$blockquote: $type-border !default;
$sans:
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  system-ui,
  Roboto,
  "Helvetica Neue",
  Arial,
  sans-serif,
  Apple Color Emoji,
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji" !default;
$mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
  "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
  "Fira Mono", "Droid Sans Mono", "Courier New", monospace, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$heading-font-family: $sans !default;
$header-title-font-family: $heading-font-family !default;
$header-subtitle-font-family: $heading-font-family !default;

// Fonts
.heading,
.serif,
.sans {
  font-family: $sans;
}
.mono {
  font-family: $mono;
}

body > header h1 {
  font-size: 2.2em;
  @extend .heading;
  font-family: $header-title-font-family;
  font-weight: normal;
  line-height: 1.2em;
  margin-bottom: 0.6667em;
}
body > header h2 {
  font-family: $header-subtitle-font-family;
}

body {
  line-height: 1.5em;
  color: $text-color;
  @extend .serif;
}
h1 {
  font-size: 2.2em;
  line-height: 1.2em;
}

@media only screen and (min-width: 992px) {
  body {
    font-size: 1.15em;
  }
  h1 {
    font-size: 2.6em;
    line-height: 1.2em;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend .heading;
  text-rendering: optimizelegibility;
  margin-bottom: 1em;
  font-weight: bold;
}
h2,
section h1 {
  font-size: 1.5em;
}
h3,
section h2,
section section h1 {
  font-size: 1.3em;
}
h4,
section h3,
section section h2,
section section section h1 {
  font-size: 1em;
}
h5,
section h4,
section section h3 {
  font-size: 0.9em;
}
h6,
section h5,
section section h4,
section section section h3 {
  font-size: 0.8em;
}

p,
article blockquote,
ul,
ol {
  margin-bottom: 1.5em;
}

ul {
  list-style-type: disc;
  list-style-position: inside;
  ul {
    list-style-type: circle;
    margin-bottom: 0px;
    ul {
      list-style-type: square;
      margin-bottom: 0px;
    }
  }
}

ol {
  list-style-type: decimal;
  ol {
    list-style-type: lower-alpha;
    margin-bottom: 0px;
    ol {
      list-style-type: lower-roman;
      margin-bottom: 0px;
    }
  }
}

ul,
ol {
  &,
  ul,
  ol {
    margin-left: 1.3em;
  }
}
ul,
ol {
  ul,
  ol {
    margin-bottom: 0em;
  }
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

sup,
sub {
  font-size: 0.75em;
  position: relative;
  display: inline-block;
  padding: 0 0.2em;
  line-height: 0.8em;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.5em;
}

a[rev="footnote"] {
  font-size: 0.75em;
  padding: 0 0.3em;
  line-height: 1;
}

q {
  font-style: italic;
  &:before {
    content: "\201C";
  }
  &:after {
    content: "\201D";
  }
}

em,
dfn {
  font-style: italic;
}

strong,
dfn {
  font-weight: bold;
}

del,
s {
  text-decoration: line-through;
}

abbr,
acronym {
  border-bottom: 1px dotted;
  cursor: help;
}

pre,
code,
tt {
  @extend .mono;
}

hr {
  margin-bottom: 0.2em;
}

small {
  font-size: 0.8em;
}

big {
  font-size: 1.2em;
}

article blockquote {
  $bq-margin: 1.2em;
  font-style: italic;
  position: relative;
  font-size: 1.2em;
  line-height: 1.5em;
  padding-left: 1em;
  border-left: 4px solid rgba($text-color-light, 0.5);
  cite {
    font-style: italic;
    a {
      color: $text-color-light !important;
      word-wrap: break-word;
    }
    &:before {
      content: "\2014";
      padding: {
        right: 0.3em;
        left: 0.3em;
      }
      color: $text-color-light;
    }
  }
  @media only screen and (min-width: 992px) {
    padding-left: 1.5em;
    border-left-width: 4px;
  }
}

.pullquote-right:before,
.pullquote-left:before {
  /* Reset metrics. */
  padding: 0;
  border: none;

  /* Content */
  content: attr(data-pullquote);

  /* Pull out to the right, modular scale based margins. */
  float: right;
  width: 45%;
  margin: 0.5em 0 1em 1.5em;

  /* Baseline correction */
  position: relative;
  top: 7px;
  font-size: 1.4em;
  line-height: 1.45em;
}

.pullquote-left:before {
  /* Make left pullquotes align properly. */
  float: left;
  margin: 0.5em 1.5em 1em 0;
}

/* @extend this to force long lines of continuous text to wrap */
.force-wrap {
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
}

// html,
// body,
// button {
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
