.sharing {
  p.meta + & {
    padding: {
      top: 1em;
      left: 0;
    }
    border-top: 1px dashed $type-border;
  }
}

#fb-root {
  display: none;
}
