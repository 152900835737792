// This File is imported last, and will override other styles in the cascade
// Add styles here to make changes without digging in too much

.edit-link {
  float: right;
  margin-top: -2em;
}

.load-comments {
  text-align: center;
  padding-bottom: 0.5em;
}
