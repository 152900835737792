body > nav {
  position: relative;
  background-color: $nav-bg;
  background-image: $nav-bg-back;
  border: {
    top: 1px solid $nav-border-top;
    bottom: 1px solid $nav-border-bottom;
  }
  padding-top: 0.35em;
  padding-bottom: 0.35em;
  font-size: 0.9em;
  ul {
    float: left;
    margin: 0;
    display: block;
    padding-top: 0.15em;

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    > li {
      padding: 0;
      float: left;
      list-style-type: none;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
  ul.subscription {
    margin-left: 0.8em;
    float: right;
    li:last-child a {
      padding-right: 0;
    }
  }
  ul li {
    margin: 0;
  }
  a {
    @include link-colors($nav-color, $nav-color-hover, $visited: $nav-color);
    font-family: $sans;
    text-shadow: lighten($nav-bg, 12) 0 1px;
    float: left;
    text-decoration: none;
    font-size: 1.1em;
    padding: 0.1em 0;
    line-height: 1.5em;
  }
  li + li {
    border-left: 1px solid $nav-border-left;
    margin-left: 0.8em;
    a {
      padding-left: 0.8em;
      border-left: 1px solid $nav-border-right;
    }
  }
}
