html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

table {
  --border-color: color-mix(in lch, transparent, currentColor);
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid;
  border-color: var(--border-color);
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

th,
td {
  padding: 0.25em;
  border: 1px solid;
  border-color: var(--border-color);
}

q,
blockquote {
  quotes: none;
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

@mixin link-colors(
  $normal,
  $hover: false,
  $active: false,
  $visited: false,
  $focus: false
) {
  color: $normal;
  @if $visited {
    &:visited {
      color: $visited;
    }
  }
  @if $focus {
    &:focus {
      color: $focus;
    }
  }
  @if $hover {
    &:hover {
      color: $hover;
    }
  }
  @if $active {
    &:active {
      color: $active;
    }
  }
}

// a link that only has an underline when you hover over it
@mixin hover-link {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
