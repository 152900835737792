article {
  padding-top: 1em;
  a {
    @extend .force-wrap;
  }
  header {
    position: relative;
    padding-top: 2em;
    padding-bottom: 1em;
    margin-bottom: 1em;
    h1 {
      margin: 0;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    p {
      font-size: 0.9em;
      color: $text-color-light;
      margin: 0;
      &.meta {
        @extend .sans;
        text-transform: uppercase;
        position: absolute;
        top: 0;
      }
    }
    @media only screen and (min-width: 768px) {
      margin-bottom: 1.5em;
      padding-bottom: 1em;
    }
  }
  h2 {
    padding-top: 0.8em;
  }
  .entry-content & h2:first-child,
  header + h2 {
    padding-top: 0;
  }
  h2:first-child,
  header + h2 {
    background: none;
  }
  .feature {
    padding-top: 0.5em;
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px dashed $type-border;
    font-size: 2em;
    font-style: italic;
    line-height: 1.3em;
  }

  > img,
  video,
  .flash-video {
    @extend .flex-content;
    @extend .basic-alignment;
    @include shadow-box;
  }
  video,
  .flash-video {
    margin: 0 auto 1.5em;
  }
  video {
    display: block;
    width: 100%;
  }
  .flash-video {
    > div {
      position: relative;
      display: block;
      padding-bottom: 56.25%;
      padding-top: 1px;
      height: 0;
      overflow: hidden;
      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  > footer {
    padding-bottom: 2.5em;
    margin-top: 2em;
    @extend .sans;
    p.meta {
      margin-bottom: 0.8em;
      font-size: 0.85em;
      clear: both;
      overflow: hidden;
    }
    .byline + time:before,
    time + time:before,
    .comments:before,
    .byline ~ .categories:before {
      @extend .separator;
    }
  }
}
article + article {
  .blog-index & {
    border-top: 1px dashed $type-border;
  }
}
#content .blog-index {
  padding: {
    top: 0;
    bottom: 0;
  }
  article {
    padding-top: 2em;
  }
  article header {
    background: none;
    padding-bottom: 0;
  }
  article h1 {
    font-size: 2.2em;
    a {
      color: inherit;
      &:hover {
        color: $link-color-hover;
      }
    }
  }

  a[rel="full-article"] {
    background: darken($main-bg, 5);
    display: inline-block;
    padding: 0.4em 0.8em;
    margin-right: 0.5em;
    text-decoration: none;
    color: mix($text-color, $text-color-light);
    transition: background-color 0.5s;
    &:hover {
      background: $link-color-hover;
      text-shadow: none;
      color: $main-bg;
    }
  }

  footer {
    @extend .sans;
    margin-top: 1em;
  }
}

.separator {
  content: "\2022 ";
  padding: 0 0.4em 0 0.2em;
  display: inline-block;
}

#content div.pagination {
  text-align: center;
  font-size: 0.95em;
  position: relative;
  border-top: 1px dashed $type-border;
  padding: {
    top: 1.5em;
    bottom: 1.5em;
  }
  a {
    color: $text-color-light;
    &.prev {
      position: absolute;
      left: 0;
    }
    &.next {
      position: absolute;
      right: 0;
    }
    &:hover {
      color: $link-color-hover;
    }
    &[href*="archive"] {
      &:before,
      &:after {
        content: "\2014";
        padding: 0 0.3em;
      }
    }
  }
}

.entry-content img {
  max-width: 100%;
}
