.side-shadow-border {
  box-shadow: lighten($sidebar-bg, 5) 0 1px;
}
aside.sidebar {
  overflow: hidden;
  color: $sidebar-color;
  text-shadow: lighten($sidebar-bg, 8) 0 1px;
  a {
    @extend .force-wrap;
  }
  .sidebar-section {
    @extend .sans;
    font-size: 0.8em;
    line-height: 1.4em;
    margin-bottom: 1.5em;
  }
  h2 {
    margin: 1.5em 0 0;
    padding-bottom: 0.2em;
    border-bottom: 1px solid $sidebar-border;
    @extend .side-shadow-border;
    + p {
      padding-top: 0.4em;
    }
  }
  img {
    @extend .flex-content;
    @extend .basic-alignment;
    @include shadow-box($border: #fff 0.3em solid);
  }
  ul {
    margin-bottom: 0.5em;
    margin-left: 0;
  }
  li {
    list-style: none;
    padding: 0.5em 0;
    margin: 0;
    border-bottom: 1px solid $sidebar-border;
    @extend .side-shadow-border;
    p:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: inherit;
    transition: color 0.5s;
  }
  &:hover a {
    color: $sidebar-link-color;
    &:hover {
      color: $sidebar-link-color-hover;
    }
  }
}
.aside-alt-link {
  color: $sidebar-link-color-subdued;
  &:hover {
    color: $sidebar-link-color-subdued-hover;
  }
}
