@mixin shadow-box(
  $border: #fff 0.5em solid,
  $shadow: rgba(#000, 0.15) 0 1px 4px,
  $border-radius: 0.3em
) {
  border-radius: $border-radius;
  box-shadow: $shadow;
  box-sizing: border-box;
  border: $border;
}

@mixin selection($bg, $color: inherit, $text-shadow: none) {
  * {
    &::-moz-selection {
      background: $bg;
      color: $color;
      text-shadow: $text-shadow;
    }
    &::-webkit-selection {
      background: $bg;
      color: $color;
      text-shadow: $text-shadow;
    }
    &::selection {
      background: $bg;
      color: $color;
      text-shadow: $text-shadow;
    }
  }
}

@function text-color($color, $dark: dark, $light: light) {
  $text-color: (
      (red($color) * 299) + (green($color) * 587) + (blue($color) * 114)
    ) / 1000;
  $text-color: if($text-color >= 150, $dark, $light);
  @return $text-color;
}
