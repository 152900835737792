$animationEasing: cubic-bezier(0.455, 0.03, 0.515, 0.955) !default;

.card {
  display: block;
  overflow: hidden;
  margin: 15px 0;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  color: inherit;
  transition: background-color 0.3s $animationEasing,
    box-shadow 0.3s $animationEasing;
  text-decoration: none;
  max-width: 768px;

  &:hover,
  &:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    color: inherit;
    text-decoration: none;
  }

  &:visited {
    color: inherit;
  }

  &:active {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  }
}

.card__title {
  margin-top: 0;
  margin-bottom: 0;
  padding: 1.5rem 1.5rem 0;
}

.card__description {
  padding: 1.5rem 1.5rem 0;
}

.card__detail {
  padding-bottom: 1.5rem;
}

article .card__depiction {
  display: block;
  border-radius: 0;
  box-shadow: none;
  border: 0;
}
