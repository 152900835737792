// Here you can easily change your sites's layout.
// To give it a try, uncomment some of the lines below, make changes, rebuild your blog, and see how it works.

//$header-font-size: 1em;
//$header-padding-top: 1.5em;
//$header-padding-bottom: 1.5em;

//$max-width: 1350px;
//$indented-lists: true;

// Padding used for layout margins
//$pad-min: 18px;
//$pad-narrow: 25px;
//$pad-medium: 35px;
//$pad-wide: 55px;

// Sidebar widths used in media queries
//$sidebar-width-medium: 240px;
//$sidebar-pad-medium: 15px;
//$sidebar-pad-wide: 20px;
//$sidebar-width-wide: 300px;
